<template>
  <form @submit.prevent="proceed">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4 class="mb-3">Contact Information</h4>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Post property as</label>
            <v-select
              placeholder="Post property as"
              :options="agencies"
              label="name"
              v-model="selected_agency"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Phone Number To Contact</label>
            <input
              type="text"
              v-model="property.phone_number"
              placeholder="Phone Number"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Whatsapp number To Contact</label>
            <input
              type="text"
              v-model="property.whatsapp"
              class="form-control"
              placeholder="Whatsapp Number"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Email To Contact</label>
            <input
              type="text"
              v-model="property.email"
              class="form-control"
              placeholder="Email"
            />
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <div class="form-group">
            <button class="btn btn-theme-light-2 rounded">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
</template>

<script>
export default {
  mounted() {
    this.selected_agency = this.agencies.filter(agency => {
      return agency.type == this.property.posted_by.type && agency.id == this.property.posted_by.id
    })[0]
  },

  data() {
    return {
      selected_agency: {}
    }
  },

  props: ['property', 'agencies'],

  methods: {
    proceed() {
      let data = {
        agency_id: this.selected_agency.id,
        agency_type: this.selected_agency.type,
        phone_number: this.property.phone_number,
        whatsapp: this.property.whatsapp,
        email: this.property.email
      }

      this.$axios
        .post(`/api/v1/my-listings/${this.property.slug}/edit/contacts`, data)
        .then((response) => {
          this.$router.push({
            name: "admin.properties.show",
            params: { id: response.data.id },
          });
        });
    }
  },

  watch: {
    selected_agency() {
      this.property.phone_number = this.selected_agency.phone_number
      this.property.whatsapp = this.selected_agency.whatsapp
      this.property.email = this.selected_agency.email
    }
  }
};
</script>
