<template>
  <div>
    <span class="badge badge-info p-2" v-if="selected_parent != null && selected_parent.children.length > 0">{{ selected_parent.name }} <a href="#" @click.prevent="selected_parent = null; $emit('input', null)" class="ms-2"><i class="lni lni-close text-white"></i></a></span>
    <v-select
      :options="categories"
      label="name"
      placeholder="Select Category"
      class="form-control"
      v-model="selected_parent"
      v-if="selected_parent == null || selected_parent.children.length == 0"
    />
    <v-select
      :options="selected_parent.children"
      label="name"
      placeholder="Select Sub Category"
      class="form-control"
      v-model="selected_category"
      v-if="selected_parent != null && selected_parent.children.length > 0"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected_parent: null,
      selected_category: null
    }
  },

  props: ['categories'],

  watch: {
    selected_category() {
      this.$emit('input', this.selected_category)
    },

    selected_parent() {
      if(this.selected_parent == null) {
        this.selected_category = null
        this.$emit('input', null)
      } else {
        if(this.selected_parent.children.length == 0) {
          this.$emit('input', this.selected_parent)
        }
      }
    }
  }
}
</script>