<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label class="required">Title</label>
        <input
          type="text"
          class="form-control"
          placeholder="Write a descripive title for your property"
          v-model="property.name"
        />
        <span class="invalid-feedback d-block" v-if="errors.name"
          >This field is required</span
        >
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="required">Type</label>
        <select v-model="property.type" class="form-control" required>
          <option value="buy">For Sale</option>
          <option value="rent">For Rent</option>
          <option value="bnb">RealBnB</option>
          <option value="sharespace">ShareSpace</option>
        </select>
        <span class="invalid-feedback d-block" v-if="errors.type"
          >This field is required</span
        >
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group" v-if="change_category">
        <label class="required">Category</label>
        <a
          href="#"
          @click.prevent="
            change_category = false;
            selected_category = null;
          "
          class="float-end text-primary"
          >cancel</a
        >
        <category-select :categories="categories" v-model="selected_category" />
        <span class="invalid-feedback d-block" v-if="errors.category"
          >This field is required</span
        >
      </div>
      <div class="form-group" v-if="!change_category">
        <label class="required">Category</label>
        <a
          href="#"
          @click.prevent="change_category = true"
          class="float-end text-primary"
          >change</a
        >
        <input
          type="text"
          disabled
          :value="property.category"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group" v-if="change_region">
        <label class="required">Region</label>
        <a
          href="#"
          @click.prevent="
            change_region = false;
            selected_region = null;
          "
          class="float-end text-primary"
          >cancel</a
        >
        <region-select :regions="regions" v-model="selected_region" />
        <span class="invalid-feedback d-block" v-if="errors.region"
          >This field is required</span
        >
      </div>
      <div class="form-group" v-if="!change_region">
        <label class="required">Region</label>
        <a
          href="#"
          @click.prevent="change_region = true"
          class="float-end text-primary"
          >change</a
        >
        <input
          type="text"
          disabled
          :value="property.region"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div :class="`col-md-${property.type != 'buy' ? '9' : '12'}`">
          <div class="form-group">
            <label class="required"
              >Amount <small class="text-muted">Number in KES</small></label
            >
            <input
              type="number"
              v-model="property.amount"
              placeholder="Amount"
              class="form-control"
            />
            <span class="invalid-feedback d-block" v-if="errors.amount"
              >This field is required</span
            >
          </div>
        </div>
        <div class="col-md-3" v-if="property.type != 'buy'">
          <div class="form-group">
            <label class="text-white">Frequency</label>
            <select v-model="property.payment_frequency" class="form-control">
              <option value="day">Per Day</option>
              <option value="week">Per Week</option>
              <option value="month">Per Month</option>
              <option value="year">Per Year</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Video Url</label>
        <input
          type="text"
          v-model="property.video_url"
          placeholder="Video URL"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label class="required">Description</label>
        <textarea
          class="form-control"
          placeholder="Property Description"
          v-model="property.description"
          rows="4"
        ></textarea>
        <span class="invalid-feedback d-block" v-if="errors.description"
          >This field is required</span
        >
      </div>
    </div>
    <div class="col-md-12">
      <a href="#" @click.prevent="updateInfo" class="btn btn-primary"
        ><i class="lni lni-save me-2"></i>Update</a
      >
    </div>
  </div>
</template>

<script>
import CategorySelect from "../CategorySelect.vue";
import RegionSelect from "../RegionSelect.vue";

export default {
  components: {
    CategorySelect,
    RegionSelect,
  },

  data() {
    return {
      categories: [],
      regions: [],
      errors: {},
      change_category: false,
      change_region: false,
      is_loading: false,
      selected_category: null,
      selected_region: null,
    };
  },

  props: {
    property: {
      default() { return {} }
    }
  },

  methods: {
    updateInfo() {
      let data = {
        property_id: this.property.id,
        name: this.property.name,
        type: this.property.type,
        amount: this.property.amount,
        description: this.property.description,
        video_url: this.property.video_url,
      };

      if (this.property.type != "buy") {
        data.payment_frequency = this.property.payment_frequency;
      }

      if (this.selected_category != null) {
        data.category_id = this.selected_category.id;
      }

      if (this.selected_region != null) {
        data.region_id = this.selected_region.id;
      }

      this.$axios
        .post(`/api/v1/my-listings/${this.$route.params.slug}/edit/info`, data)
        .then((response) => {
          this.$router.push({ name: 'admin.properties.show', params: { id: response.data.id }})
        });
    },
  },
};
</script>
