<template>
  <div>
    <a
      href="#"
      @click.prevent="show_add_images = true"
      class="btn btn-primary btn-sm float-end"
      ><i class="lni lni-plus me-2"></i>Add New</a
    >
    <h4 class="text-muted">
      <small class="fw-normal fs-6">Minimum: 3</small>
    </h4>
    <hr />
    <div class="row">
      <div class="col-md-3" v-for="(image, i) in images" :key="`image-${i}`">
        <div
          :class="
            image.main
              ? 'border border-primary border-3 p-2'
              : 'border border-default p-2'
          "
        >
          <img :src="image.url" :alt="image.id" style="width: 100%" />
          <p v-if="image.main" class="text-center mb-0 mt-2">Main Image</p>
          <p
            
            class="d-flex justify-content-between mb-0 mt-2"
          >
            <a @click.prevent="makeMain(image)" href="#" class="text-success" v-if="!image.main"
              >make main</a
            >
            <a @click.prevent="deleteImage(image)" href="#" class="text-danger" v-if="!image.main && images.length > 3"
              >delete</a
            >
          </p>
        </div>
      </div>
    </div>
    <modal :show="show_add_images" @close="show_add_images = false">
      <template slot="header">Upload Images</template>
      <form @submit.prevent="uploadImages">
        <div class="form-group">
          <label>Select Images</label>
          <input type="file" class="form-control" multiple ref="upload_images">
        </div>
        <div class="form-group text-end">
          <button class="btn btn-primary"><i class="lni lni-upload me-2"></i>Upload</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show_add_images: false,
      images: this.the_images
    }
  },

  props: {
    the_images: {
      default() { return [] }
    },
    property: {
      default() { return {} }
    }
  },

  methods: {
    uploadImages() {
      this.loading_images = true
      let data = new FormData
      data.append('property_id', this.property.id)

      Object.values(this.$refs.upload_images.files).forEach((file, i) => {
        data.append(`images[${i}]`, file)
      })

      this.$axios.post(`/api/v1/my-listings/${this.$route.params.slug}/edit/upload-images`, data).then(response => {
        this.images = response.data.images
        this.show_add_images = false
        this.loading_images = false
      })
    },

    makeMain(image) {
      this.loading_images = true

      let data = {
        property_id: this.property.id,
        image_id: image.id
      }

      this.$axios.post(`/api/v1/my-listings/${this.$route.params.slug}/edit/make-main`, data).then(response => {
        this.images = response.data.images
        this.loading_images = false
      })
    },

    deleteImage(image) {
      this.loading_images = true

      let data = {
        property_id: this.property.id,
        image_id: image.id
      }

      this.$axios.post(`/api/v1/my-listings/${this.$route.params.slug}/edit/delete-image`, data).then(response => {
        this.images = response.data.images
        this.loading_images = false
      })
    }
  }
};
</script>
