<template>
  <div>
    <form @submit.prevent="proceed">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4 class="mb-3">Property Details</h4>
        </div>
      </div>
      <div class="form-group mt-5">
        <p>Select your property's main amenities</p>
        <div class="row">
          <div
            class="col-md-2 text-center mb-3"
            v-for="(amenity, i) in amenities"
            :key="`amenity-${i}`"
          >
            <label :for="`amenity-${i}`">{{ amenity.name }}</label>
            <input
              type="checkbox"
              :id="`amenity-${i}`"
              :value="amenity.name"
              :name="`amenity-${i}`"
              class="d-block mx-auto"
              v-model="selected_amenities"
            />
          </div>
        </div>
      </div>
      <div class="form-group mt-5">
        <p>
          Enter your property's details
          <small class="text-muted">Leave blank to exclude</small>
        </p>
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="bedrooms">Bedrooms</label>
            <input
              type="text"
              placeholder="Bedrooms"
              v-model="property.bedrooms"
              class="form-control"
            />
          </div>
          <div
            class="col-md-4 mb-3"
            v-for="(detail, i) in details"
            :key="`detail-${i}`"
          >
            <label :for="`detail-${i}`">{{ detail.key }}</label>
            <select
              v-model="selected_details[detail.key]"
              :name="selected_details[detail.key]"
              class="form-control"
              v-if="detail.type == 'select'"
            >
              <option
                v-for="(value, j) in detail.options"
                :key="`option-${i * j}`"
                :value="value"
              >
                {{ value }}
              </option>
            </select>
            <input
              v-if="detail.type == 'text'"
              v-model="selected_details[detail.key]"
              :name="selected_details[detail.key]"
              type="text"
              class="form-control"
              :placeholder="detail.key"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-theme-light-2 rounded">Update</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  mounted() {
    let details = {};

    this.property.details.forEach((detail) => {
      details[detail.key] = detail.value;
    });

    this.selected_details = details;
  },

  data() {
    return {
      selected_amenities: this.property.amenities,
      selected_details: {},
    };
  },

  props: ["amenities", "details", "property"],

  methods: {
    proceed() {
      let data = {
        amenities: this.selected_amenities,
        details: this.selected_details,
      }

      this.$axios
        .post(`/api/v1/my-listings/${this.property.slug}/edit/details`, data)
        .then((response) => {
          this.$router.push({
            name: "admin.properties.show",
            params: { id: response.data.id },
          });
        });
    },
  },
};
</script>
